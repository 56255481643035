<template>
    <div class="cellbox">


        <div class="title">
            {{val.name}}
        </div>
        <div class="subtitle">
            联单概览
        </div>



        <!-- <div class="list">
            <span>感染性</span>
            <span>100包</span>
            <span>185.5KG</span>
        </div> -->



        <div class="list" v-for="(item,index) in val.array" :key="index" :val="item">
            <span>{{item.name}}</span>
            <span>{{item.num}}包</span>
            <span>{{item.weight}}KG</span>
        </div>






    </div>
</template>

<script>
    export default {

        data() {
            return {

            }
        },
        props: ['val'],

        computed: {

        },

        created() {

        },

        mounted() {

        },

        methods: {},



    }
</script>

<style lang="scss" scoped>
    .cellbox {
        border: 1px solid #0096FF;
        box-sizing: border-box;

        width: calc(100% - 30px);

        padding: 15px;

        margin-bottom: 10px;


        .title {
            // border: 1px green solid;
            // box-sizing: border-box;

            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #6CEDFF;
        }

        .subtitle {
            border-bottom: 1px #0096FF dotted;
            box-sizing: border-box;

            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #0096FF;

            padding: 10px 0;
        }

        .list {
            // border: 1px green solid;
            box-sizing: border-box;

            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #6CEDFF;
            line-height: 25px;

            // width: 90%;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }



    }
</style>