<template>
    <div>

        <div id="main" class="map1"></div>

    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import TJ from './TJ.json'

    export default {

        data() {
            return {

            }
        },

        computed: {

        },

        created() {

        },

        mounted() {

            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById('main'));

            window.addEventListener('resize', () => {
                myChart.resize()
            });

            myChart.showLoading();

            myChart.hideLoading();




            myChart.on('click', (params) => {
                // 控制台打印数据的名称
                console.log("~~~~~~~~~~", params.name, params.value);

                this.$parent.filterData(params.name)

            });




            echarts.registerMap('天津', TJ);
            myChart.setOption(
                ({
                    // title: {
                    //     text: '大标题',
                    //     subtext: '小标题',
                    //     sublink: 'https://www.baidu.com/'
                    // },

                    // tooltip: {
                    //     trigger: 'item',
                    //     formatter: '{b}<br/>{c} (p / km2)'
                    // },

                    // visualMap: {
                    //     show: false,
                    //     min: 0,
                    //     max: 10,
                    //     text: ['高', '低'],
                    //     realtime: false,
                    //     calculable: true,
                    //     inRange: {
                    //         color: ['#ff0000', '#00000000'],
                    //     },

                    // },



                    geo: {
                        map: '天津',
                        roam: true,
                        zoom: 1.24,
                        label: {
                            normal: {
                                show: true,
                                color: "#00FEFB",
                            },

                            emphasis: {
                                color: "#fff",
                            }
                        },

                        itemStyle: {

                            normal: {
                                borderColor: '#355B95',
                                borderWidth: 2,
                                // borderType: [5, 10],
                                // borderDashOffset: 5,
                                areaColor: "#0F1360",
                            },

                            emphasis: {
                                show: true,
                                areaColor: "#355B95",
                            },
                        },



                    },

                    series: [



                        {
                            name: '天津18区人口密度',
                            // type: 'scatter',
                            type: 'effectScatter',
                            coordinateSystem: 'geo',


                            // symbol: 'image://https://www.baidu.com/img/flexible/logo/pc/result.png',

                            symbol: 'circle',

                            // symbolSize: 50,
                            // color: 'red',

                            // // itemStyle: {
                            // //     color: 'red'
                            // // },

                            // emphasis: {
                            //     scale: true,

                            //     areaColor: "#ff00ff",

                            //     select: {
                            //         label: {
                            //             show: true,
                            //             color: "#ff00ff",

                            //         },
                            //     }
                            // },


                            geoIndex: 0,
                            symbolSize: function(params) {
                                return 10;
                            },


                            itemStyle: {
                                shadowBlur: 5,
                                // color: '#ffff00',

                                color: function(params) {

                                    var a = params.value[2];

                                    // if (a > 1) {
                                    return '#ffff00'
                                    // } else {
                                    //     return '#ff0000'
                                    // }

                                },

                            },

                            encode: {
                                tooltip: 2
                            },

                            data: [

                                {
                                    name: '静海区',
                                    value: [116.97983, 38.95375, 1]
                                },
                                {
                                    name: '津南区',
                                    value: [117.36377, 38.94475, 1]
                                },
                                {
                                    name: '滨海新区',
                                    value: [117.71828, 39.01068, 1]
                                },
                                {
                                    name: '东丽区',
                                    value: [117.38668, 39.10299, 18]
                                },
                                {
                                    name: '河东区',
                                    value: [117.25973, 39.13437, 18]
                                },
                                {
                                    name: '河北区',
                                    value: [117.20514, 39.15346, 18]
                                },
                                {
                                    name: '红桥区',
                                    value: [117.15723, 39.17254, 18]
                                },
                                {
                                    name: '西青区',
                                    value: [117.01574, 39.10022, 18]
                                },
                                {
                                    name: '北辰区',
                                    value: [117.14275, 39.23062, 18]
                                },
                                {
                                    name: '武清区',
                                    value: [117.05250, 39.38948, 18]
                                },
                                {
                                    name: '南开区',
                                    value: [117.15723, 39.10254, 18]
                                },
                                {
                                    name: '和平区',
                                    value: [117.19023, 39.11554, 18]
                                },
                                {
                                    name: '河西区',
                                    value: [117.22023, 39.08554, 18]
                                },
                                {
                                    name: '宁河区',
                                    value: [117.51023, 39.28554, 18]
                                },
                                {
                                    name: '宝坻区',
                                    value: [117.51023, 39.58554, 18]
                                },
                                {
                                    name: '蓟州区',
                                    value: [117.45023, 39.88554, 18]
                                },










                            ],

                        },


                    ]
                })
            );




        },




        methods: {


        },



    }
</script>

<style lang="scss" scoped>
    .map1 {
        // border: 1px green solid;
        box-sizing: border-box;
        width: 100%;
        // height: calc(100vh - 160px)
        // height: calc(100vh - 550px);
        height: 100%;

        // min-height: calc(100vh - 190px);
        // height: 80%;
        // height: calc(100% - 120px);
        overflow: hidden;


        // background: #1F8CF1;
        background-size: 95% 95%;
        background-repeat: no-repeat;
        background-position: center;


        background-image: url('../../../../public/surround.png');

    }
</style>