<template>
    <div class="cellbox">

        <div class="dust">
            <div class="key">
                医院名称
            </div>
            <div class="val">
                {{val.name}}
            </div>

        </div>

        <div class="dust">
            <div class="key">
                原因说明
            </div>
            <div class="val">
                {{val.info}}
            </div>

        </div>

        <div class="dust">
            <div class="key">
                上级医院
            </div>
            <div class="val">
                {{val.up}}
            </div>

        </div>





    </div>
</template>

<script>
    export default {

        data() {
            return {

            }
        },
        props: ['val'],
        computed: {

        },

        created() {

        },

        mounted() {

        },

        methods: {},



    }
</script>

<style lang="scss" scoped>
    .cellbox {
        // border: none;
        box-sizing: border-box;

        width: calc(100% - 20px);

        padding: 15px;

        border-bottom: 1px white solid;

        border-image-source: url('../../../../../public/board-line.png');
        border-image-slice: 50;


        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;


        .dust {
            // border: 1px green solid;
            box-sizing: border-box;

            width: 50%;
            padding: 2px;

            .key {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #0096FF;
                margin-bottom: 3px;
            }

            .val {
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #6CEDFF;
            }



        }

        .dust:nth-of-type(2) {
            // width: 100%;
            text-align: right;
        }

        .dust:nth-of-type(3) {
            width: 100%;
        }




    }
</style>