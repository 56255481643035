<template>
    <div>

        <!-- 
        首页：社区卫生服务中心用之前社区卫生服务中心首页
        卫健委：用之前卫健委的首页
        危废管理员：显示当前包含天津地图的首页
        -->

        <!-- 危废管理员 -->
        <Home v-if="user.position_id==17"></Home>

        <!-- 卫健委 -->
        <iframe v-if="user.position_id==16" style="
          
            box-sizing: border-box;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;" width="100%" height="100%"
            :src="'http://yifei-php-index.tjjtln.com/daping/wjw/index.html?account='+this.user.username"
            frameborder="0"></iframe>

        <!-- scrolling="no" -->

        <!-- 社区 -->
        <iframe v-if="user.position_id==14||user.position_id==11" style="
           
            box-sizing: border-box;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;" width="100%" height="100%"
            :src="'http://yifei-php-index.tjjtln.com/daping/ceshi/adminindex.html?account='+this.user.username"
            frameborder="0"></iframe>


        <!-- :src="'http://yifei-php-index.tjjtln.com/daping/ceshi/index.html?account='+this.user.username" -->
        <!-- http://yifei-php-index.tjjtln.com/daping/ceshi/adminindex.html?account=hpnym -->


        <!-- 卫健委 -->
        <!-- http://yifei-php-index.tjjtln.com/daping/wjw/index.html?account=jnwjw -->

        <!-- 社区医院 -->
        <!-- http://yifei-php-index.tjjtln.com/daping/ceshi/index.html?account=sjsqyy -->

    </div>
</template>

<script>
    import Home from './zShowView/Home.vue'

    export default {
        components: {
            Home
        },

        data() {
            return {
                user: {}
            }
        },

        computed: {

        },

        created() {

            this.user = JSON.parse(localStorage.getItem("user"))

            console.log(" ", this.user.username);


        },

        mounted() {

        },

        methods: {

        },
    }
</script>

<style lang="scss" scoped>

</style>