<template>
    <div class="home_index" @dblclick="home">

        <div class="map">

            <div class="box" style="flex: 2;">

                <div class="boxTitle">未提交联单详情</div>

                <div class="boxcontent">

                    <cell v-for="(item,index) in list" :key="index" :val="item" />

                </div>

            </div>



            <div class="box" style="flex: 5;">

                <div class="titleBox">

                    <div class="bTip" style="display: flex; justify-content: flex-end;align-items: center;">
                        今日联单统计
                    </div>
                    <div class="bTip">
                        <span>已提交联单医院<font>{{Math.round(Math.random() * 100 + 20)}}</font>家</span>
                        <span>未提交联单医院<font>{{Math.round(Math.random() * 100 + 20)}}</font>家</span>
                    </div>

                    <!-- <span style="color: white;" @click.stop="backAll"> 全部</span> -->
                </div>


                <mapa class="mapBox"></mapa>

            </div>

            <div class="box" style="flex: 2;">

                <div class="boxTitle">已提交联单统计</div>

                <div class="boxcontent boxcontent2">

                    <cell2 v-for="(item,index) in list1" :key="index" :val="item" />

                </div>

            </div>

        </div>

    </div>
</template>

<script>
    import mapa from './map.vue'
    import cell from './components/cell.vue'
    import cell2 from './components/cell2.vue'

    export default {
        components: {
            mapa,
            cell,
            cell2
        },

        data() {
            return {

                val: 0,





                list: [],
                list1: [],

                array: ["静海区",
                    "津南区",
                    "滨海新区",
                    "东丽区",
                    "河东区",
                    "河北区",
                    "红桥区",
                    "西青区",
                    "北辰区",
                    "武清区",
                    "南开区",
                    "和平区",
                    "河西区",
                    "宁河区",
                    "宝坻区",
                    "蓟州区",
                ]



            }
        },

        computed: {

        },

        created() {

        },

        mounted() {

            this.backAll()


            if (this.$route.path == "/view") {
                console.log("全屏");
                this.val = 1
            } else {
                this.val = 0
            }

        },

        methods: {
            home() {

                if (this.val == 0) {
                    this.$router.push('/view') //跳转界面
                } else {
                    this.$router.go(-1) //跳转界面
                }

            },


            backAll() {

                console.log("刷新");

                this.list = this.array.map(x => {

                    var a = {
                        area: x,
                        name: x + "测试卫生服务第" + Math.round(Math.random() * 5 + 5) + "中心医院",
                        info: "暂停营业",
                        up: x + "测试卫生服务中心医院上级医院"
                    }

                    return a
                })


                this.list1 = this.array.map(x => {

                    var a = {
                        area: x,
                        name: x + "测试卫生服务第" + Math.round(Math.random() * 5 + 5) + "中心医院",
                        array: [{
                            name: '感染类',
                            num: Math.round(Math.random() * 5 + 5),
                            weight: Number(Math.random() + Math.round(Math.random() * 5)).toFixed(2)
                        }, {
                            name: '新冠类',
                            num: Math.round(Math.random() * 5 + 5),
                            weight: Number(Math.random() + Math.round(Math.random() * 5)).toFixed(2)
                        }, {
                            name: '损伤类',
                            num: Math.round(Math.random() * 5 + 5),
                            weight: Number(Math.random() + Math.round(Math.random() * 5)).toFixed(2)
                        }, ]

                    }

                    return a
                })



            },


            filterData(name) {

                console.log("AAAAAAAAAAAAA", name);



                this.list = []

                for (let i = 0; i < Math.round(Math.random() * 5 + 1); i++) {

                    var a = {
                        area: name,
                        name: name + "测试卫生服务第" + Math.round(Math.random() * 5 + 5) + "中心医院",
                        info: "暂停营业",
                        up: name + "测试卫生服务中心医院上级医院"
                    }
                    this.list.push(a)
                }




                this.list1 = []

                for (let i = 0; i < Math.round(Math.random() * 5 + 1); i++) {

                    var a = {

                        area: name,
                        name: name + "测试卫生服务第" + Math.round(Math.random() * 5 + 5) + "中心医院",


                        array: [{
                            name: '感染类',
                            num: Math.round(Math.random() * 5 + 5),
                            weight: Number(Math.random() + Math.round(Math.random() * 5)).toFixed(2)
                        }, {
                            name: '新冠类',
                            num: Math.round(Math.random() * 5 + 5),
                            weight: Number(Math.random() + Math.round(Math.random() * 5)).toFixed(2)
                        }, {
                            name: '损伤类',
                            num: Math.round(Math.random() * 5 + 5),
                            weight: Number(Math.random() + Math.round(Math.random() * 5)).toFixed(2)
                        }, ]

                    }
                    this.list1.push(a)
                }











            }


        },
    }
</script>

<style lang="scss" scoped>
    .home_index {
        // border: 10px red solid;
        box-sizing: border-box;
        // width: 100%;
        // height: 100%;

        // position: relative;
        // height: 50vh !important;


        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;


        background: radial-gradient(circle, #242371, #131456);

        padding: 40px 20px;

        .map {
            // border: 1px yellow solid;
            box-sizing: border-box;
            // width: 100%;
            // min-height: calc(100vh - 160px);
            height: 100%;
            // min-height: calc(100vh - 550px);

            overflow: hidden;

            display: flex;
            // flex-direction: row;
            // align-items: center;
            // justify-content: space-between;


            .mapBox {

                // border: 1px yellow solid;
                box-sizing: border-box;

                height: calc(100% - 80px);

            }


            .box {
                // border: 1px red solid;
                box-sizing: border-box;
                width: 100%;
                // height: calc(100vh - 160px)
                // height: calc(100vh - 550px);

                // min-height: calc(100% - 160px);
                height: 100%;

                // overflow-y: auto;
                overflow: hidden;

                .boxTitle {

                    // width: 167px;
                    height: 20px;
                    line-height: 18px;
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #0096FF;

                    border-left: 7px #00FFFF solid;
                    padding-left: 10px;
                    margin: 10px 0;
                }

                .boxcontent {
                    // border: 1px red solid;
                    box-sizing: border-box;
                    width: calc(100% + 20px);
                    height: calc(100% - 50px);
                    overflow-y: auto;
                    overflow-x: hidden;
                }

                .boxcontent2 {
                    margin-left: 7px;
                }
            }
        }


        .titleBox {
            // border: 1px yellow solid;
            // box-sizing: border-box;

            width: 100%;
            max-width: 600px;
            height: 80px;
            // padding: 10px;

            // margin-left: 10%;
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            z-index: 99;

            .bTip {
                // width: 100%;
                // border: 1px red solid;
                box-sizing: border-box;
                padding: 10px;

                span {
                    // border: 1px red solid;
                    box-sizing: border-box;

                    padding: 0 30px;

                    width: 100%;
                    // display: block;
                    // height: 40px;
                    // line-height: 40px;

                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    font {
                        color: #FFFD00;
                        font-size: 30px;
                        font-weight: 700;
                        padding: 0 5px 0 15px;

                        // line-height: 60px;
                        // margin-top: 10px;
                        // border: 1px red solid;
                        // box-sizing: border-box;

                    }

                }
            }

            .bTip:nth-of-type(1) {
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #34EAE4;
                // line-height: 60px;
            }

            .bTip:nth-of-type(2) {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #17A3FD;
                line-height: 40px;

                span:nth-of-type(1) {
                    // border: 1px red solid;
                    // box-sizing: border-box;
                    border-bottom: 1px white solid;
                    border-image-source: url('../../../../public/board-line.png');
                    border-image-slice: 50;
                }

            }

        }

    }
</style>